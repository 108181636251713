<template>
  <div class="mt-4">
    <span class="mb-4" v-text="$t('request_reset_link_copy')" />
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="authResetRequest"
      @submit="submit"
    >
      <FormulateInput
        v-model.trim="values.email"
        type="email"
        name="email"
        :label="$t('email_label')"
        autocomplete="on"
        validation="bail|required|email"
      />
      <FormulateErrors />
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        :input-class="['w-full md:w-auto']"
        :outer-class="['mt-7.5']"
      >
        <i
          :class="[
            'far mr-2',
            isLoading ? 'fa-spinner-third animate-spin' : 'fa-unlock'
          ]"
        />
        {{ $t('request_reset_link') }}
      </FormulateInput>
    </FormulateForm>
    <router-link
      :to="{ name: 'AuthLogin' }"
      class="tg-mobile-link-small md:tg-desktop-link link"
    >
      <i class="far mr-2 fa-user" />
      {{ $t('return_to_login') }}
    </router-link>
  </div>
</template>

<script>
import { requestPasswordReset } from '@/services/apiService';

export default {
  name: 'AuthResetRequest',
  data() {
    return {
      values: {}
    };
  },
  methods: {
    submit(data) {
      return requestPasswordReset(data)
        .then(() => {
          this.$router.push({ name: 'AuthResetLinkSent' });
        })
        .catch(error => {
          this.$formulate.handle(error, 'authResetRequest');
        });
    }
  }
};
</script>
